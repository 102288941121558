<template>
  <div class="detailContent">
    <p><b class="title">问题简述：</b>一直以来，就诉讼投资模式的合理性，始终有国内外部分专家、学者、从业人员持消极评价，因由包括影响公正裁判、干扰律师代理、干扰诉讼解决程序、胁迫被资助人。异化诉讼价值追究等。对此，我们撰写了这份分析意见，从新时代中国特色社会主义思想及法治思想、纠纷解决市场需求、法律服务业务本质等角度，对此类评价进行分别反驳。</p>
    <p>本文字数约9000字。</p>
    <p><b class="title">正文如下：</b></p>
    <p class="header">诉讼投资与公序良俗</p>
    <p style="text-align: right">文 / 帮瀛风控高级顾问  吴振坤</p>
    <p><b class="title">一、何为诉讼投资</b></p>
    <p>诉讼投资源于市场商业需求，是以蕴含法律风险和诉讼争议的“财产权益”作为投资标的进行出资，以协议约定的投资目标为支付条件，以实现投资目标为前提来获取约定收益的一种投资行为。诉投模式最早出现在1967年的英格兰和威尔士，当时已开始设立专门诉讼基金。后澳大利亚和美国也认同并合法化诉讼投资模式，该模式渐为普通法系国家所熟知。奥地利、荷兰和德国等欧洲大陆法系国家也有一些实践。</p>
    <p>该模式在国外也称为“第三方资助”(ThirdPartyFunding,TPF)。</p>
    <p></p>
    <p>帮瀛公司成立于2015年，是国内首家专业从事诉讼投资的机构，率先在国内结合法律+科技+金融手段，开展诉讼及仲裁投资业务。</p>
    <p>2017年，新加坡和我国香港首次以法律或仲裁条例形式允许第三方资助仲裁，承认“仲裁投资”合法性。“仲裁投资”和“诉讼投资”是孪生兄弟，国内开始散见一些诉讼投资方面的学理研究。</p>
    <p>目前我国法律层面还未对“诉讼投资”有所规制，新修订的《仲裁法(征求意见稿)》也未提及“第三方资助行为”。</p>
    <p>从国内已有的诉讼投资业务模式看，“诉讼投资”是指<b>与诉讼纠纷无既有经济利益的第三方，以书面协议方式，向诉讼案件一方当事人（下称“被资助方”）提供解决纠纷所需的成本费用（通常包含诉讼费及律师费），以被资助方胜诉、止损或回款为条件收取合理收益的行为。</b></p>
    <p><b class="title">二、诉讼投资产生的原因及价值</b></p>
    <p><b>诉讼投资业务的产生，有其历史必然性。</b></p>
    <p>互联网科技的发展和法律法规制度的健全，使人们通过手机、电脑、电视、书籍查找、学习法律、法规知识越来越便利，裁判文书网的公开，让民众对行为产生的法律后果预期判断越来越精准，传统的世俗公约和风俗习惯对民众影响力和约束力进一步淡化，民众法律意识和维权意识日益增强，通过书面协议约定权利义务，通过法律途径解决问题，已成为法治社会的象征，我国逐年上涨的民商事案件量，也侧面证明我国民众法律意识的提高和司法信任度的提高。</p>
    <p>尴尬的是，<b>大部分国家诉讼都不免费。</b></p>
    <p>当事人通过法律途径维权，绕不开缴纳高额诉讼费的门槛，除了特定诉讼，大部分国家民商事诉讼都以标的额作为案件受理费计费标准，案涉标的越高，诉讼费越高，我国亦如此。</p>
    <p>随着民众维权意识的提高，案件量的增多，诉讼成本的增加，诉讼投资需求渐被市场激发，诉讼投资业务，渐次而生。就笔者实践情况来看，诉讼投资确有其价值和必要性，具体体现在以下方面：</p>
    <p><b>1、解决大标的<span class="title">商事案件</span>的成本负担需求</b></p>
    <p>婚姻、继承、监护、收养、抚养、赡养、人格、相邻关系等有人身属性的传统民事案件，以及物业、供暖、房租、水电、劳争、消费品买卖等关乎民生的小标的民事合同纠纷案件，或刑事诉讼、行政诉讼(不含行政赔偿)、公益诉讼、特别程序类案件，诉讼费交纳都有特殊规定，符合法定情形的困难人群，还有“减、免、缓”交诉讼费及“法律援助”等政策扶持，故<b>上述案件并不属于诉讼投资案件范围。</b></p>
    <p>诉讼投资机构，大多只针对<b>大标的案件</b>，且集中在<b>商事领域</b>，客户大多数为商主体(含从事商业活动的自然人和法人)，纠纷多以合同纠纷为主，诉求多以实现财产权益为目标，他们往往<b class="color">并不属于诉讼费可“减、免、缓”的主体范围，也不属于可进行法律援助的主体范围</b>，他们需要自己真金白银地掏钱打官司。</p>
    <p>诉投案件集中在商事领域，也与商主体特性相关。商主体与民事主体相比，有更强的<b>工作规划属性和资金价值最大化需求。</b></p>
    <p>对商主体而言，<b>没有比一个问题还没解决，又要花一笔钱解决这个问题更尴尬的事情了</b>。大标的案件，一旦走向诉讼，除了可能产生的诉讼费、评估费、鉴定费、公证费、保全费、担保费、公告费、执行费、调证费等官费成本外，还有一笔价格不菲的律师费等待开支。而诉讼的偶发性和低频性，律师费的差异性和不公开性，诉讼结果的难预料性和诉讼周期的拖沓性，导致<b>极少有企业能对一年诉讼案件量有准确预估</b>，对案件可能带来的回款及回款数额及时间更无法作准确预估。</p>
    <p>因此，<b>大标的诉讼，对更依赖现金流维持正常经营的企业来讲，无异于飞来横祸</b>。对那些权益受侵害被逼走投无路的商业主体来讲，有时候，<b>这笔诉讼成本资金，可能成为压倒骆驼的最后一根稻草。</b></p>
    <p>2015年，创始团队的一个老客户因资金周转出现障碍，面临无钱上诉和无钱支付律师费困境，而案件本身有较大胜诉可能，我们在为其承担上诉成本后，案件获得圆满结果，双方对此均感满意。</p>
    <p>此后我们将该模式公司化，帮瀛就是在这么一个特别契机下创立，延续至今。</p>
    <p>至于何为“<b>大标的</b>”，不同机构有不同理解。以帮瀛为例，其成立伊始就瞄准1000万以上大标的民商事争议案件(因市场需求较大，后将投资案件标准逐步提升至5000万)，该类案件，占比不超过全国民商事案件总量的1%，可以说，诉讼投资案件门槛很高。</p>
    <p>后续国内市场陆续出现多家针对商事案件的诉投机构，案件标的门槛也大多在50万元以上。</p>
    <p><b>2、解决特定案件的专业判断需求</b></p>
    <p>法律条文唾手可得，但法律知识却晦涩难懂。诉讼策略的拟定，诉讼请求的确定，诉讼法院的选择，诉讼证据的准备，诉讼材料的梳理，诉讼程序的参与，诉讼文书的撰写，都离不开专业法律知识和高超法律技能。</p>
    <p>多数当事人并无法律知识储备，部分商业主体有法务人员但并不足以应对各类民商事诉讼，大多数当事人其实并无充分的专业判断能力和自行处理能力。</p>
    <p>标的额大、案情复杂、争议多，法律规定却不太明确的特定案件，当事人承担的诉讼风险压力尤大。</p>
    <p>当事人一方面想提起诉讼主张权利，另一方面又想尽可能减少开支、降低成本风险，乃是一种理性思维下的<b>理性选择，绝非是一种“赌注”。</b></p>
    <p>诉讼的不可逆性，导致当事人的机会成本极高，<b>一旦选择错误，产生的法律后果是不可逆的</b>，并非所有当事人都能坦然接受。这就要求，做出抉择前，当事人必须有足够的谨慎性、判断力和承受力来面对未来可能出现的各种可能性结果。</p>
    <p><b>无论当事人自身的判断力如何，增加专业机构的辅助意见、引入外部资金分担风险，无疑都属于合理、良性的选择。</b></p>
    <p>诉投机构一般都有独立风控部门，风控人员大多是有丰富诉讼和法律服务经验的资深法律从业人士，对案件情况会进行全面审查，既审查案件的事实和证据、审查当事人的陈述和资信状况，也审查对方当事人的资信状况和涉诉涉执情况，基于各方事实和证据，结合法律和裁判观点、背景资料及法律经验进行分析研究，对案件胜诉和执行可能性作出有限的预判，并根据预判结果及风险大小，自行决定是否愿意投资案件、与当事人共担风险。</p>
    <p>这一系列复杂决策过程，显然<b>并非以小博大的“投机行为”，而是专业、保守、谨慎的商业投资行为</b>。</p>
    <p>以帮瀛为例，为保障案件科学决策，除风险控制部之外，还有投资决策委员会及投后管理部门，通过办案系统将办案流程节点化、规范化，提高了案件信息的透明化程度，<b>让投后服务更加专业、让投入资金尽可能安全</b>。</p>
    <p>对当事人来讲，诉投机构也是专业评估机构，可以弥补当事人专业判断能力的不足。诉投机构通过风控审核的案件，当事人依然有绝对自主权，可因合作条件、合作时机、合作方式和合作态度等各种原因拒绝投资。</p>
    <p>哪怕是未通过诉投机构风控审核的案件，当事人也能控制合理预期，理解案件法律上存在的障碍和风险，从而减少不必要的诉讼，客观上减少涌入法院诉讼的案件量。</p>
    <p>可以说，一定程度上，诉投机构起到了案件拦截作用，通过拒绝投资行为，<b class="red">实质在劝阻滥诉</b>。</p>
    <p>以帮瀛机构为例，案件风控审核标准较高，且需不同人员两次背对背审核，拒项率高达80%以上、投资率常年保持在10%以下。正是通过严格风控审核制度和风险共担原则，诉投机构和当事人间消弭了专业信任危机，实现共赢。</p>
    <p><b>3、解决特定主体的信用融资需求</b></p>
    <p>诉讼量的不断增加、诉讼成本的不断飙升、企业对法律费用的敏感性不断增强，越来越多的商业主体希望或需要获得外部资本，来助力其启动诉讼或有效参与诉讼，这是一个明确的商业需求。</p>
    <p>不过，<b class="red">尤其对于涉诉或现金流匮乏的企业而言，指望通过信用融资获得诉讼资金是不现实的</b>。商业主体更愿意把有限资金投入到商业生产经营中，以获取更高利润。传统金融机构或信托、保理等公司，更愿意获得稳定报酬，不想承担任何风险。因此，<b class="red">当企业需要资金、通过诉讼手段撬动应收账款时，传统融资通道却反而关闭了大门</b>。</p>
    <p>好在，有合法依据的诉讼和仲裁诉求，其实是有价值的资产，就像其它任何应收账款一样，可用于融资。</p>
    <p>诉投机构就是为那些无法通过传统金融机构获取融资款项，也无法通过自行借贷筹集款项，同时还不愿意承担绝对还款义务的当事人，提供了一种全新服务。</p>
    <p>针对诉讼案件成本费用的投资行为，一定程度上满足当事人诉讼成本费用信用融资的需求。</p>
    <p><b class="title">三、诉讼投资对诉讼制度的影响及反向规制</b></p>
    <p>诉讼投资客观上有其存在价值，但毕竟动机是为获得投资回报。诉投机构和被资助方利益，并非总是完全一致。</p>
    <p>有观点提出担忧，认为诉讼投资行为，客观上会对案件本身乃至社会公共利益、公序良俗、民事诉讼秩序等产生一些消极影响，比如：</p>
    <p>①影响公正裁判。法院或法官可能与诉投机构存在利益关联，影响裁判独立性和公正性。</p>
    <p>②干扰律师代理。诉投机构可能与被资助人代理律师有利益关联，或者与被资助人对方当事人的代理律师有利益关联，使被资助方律师不能最大程度维护当事人利益。</p>
    <p>③干扰诉讼程序。诉投机构以其优势地位，追求更大利益，强行给客户指派律师，影响和解结果，隐藏于背后干扰诉讼解决程序，忽视被资助人经济利益外的其他合理诉求，不利于定纷止争。</p>
    <p>④胁迫被资助人。诉投机构以停止资助为由胁迫被资助人听命于自己的诉讼策略，强化诉讼干预功能。</p>
    <p>⑤异化诉讼价值追求。将诉讼变为生意，将法院当成赌场，引发滥诉行为，让当事人失去对法律的敬畏和对诉讼行为的自我约束，不利于抑恶扬善和正确价值观的建立。</p>
    <p>事实上，<b>上述担心并非毫无道理</b>。</p>
    <p>一开始，<b>在司法环境混乱的百年前的欧洲，诉投行为也曾被普通法国家严厉禁止</b>。普通法有“禁止帮诉”和“禁止助诉获利”原则，违反该原则甚至可能构成犯罪或侵权。</p>
    <p>但随着司法透明度、文明度、专业度和公信力、权威力、干预力的提高，这些国家<b>开始陆续承认第三方资助行为的有利性和合法性，同时也制定了谨慎的反向规制措施</b>，防止第三方资助行为可能给诉讼和仲裁带来的不利影响。<b>例如</b>：</p>
    <p><b>1、英格兰和威尔士制定《诉讼资助人行为守则》</b></p>
    <p>2011年11月，英国司法部下设的民事司法理事会发布《诉讼资助人行为守则》，对第三方资助行业进行自律管理。2018年1月，《诉讼资助人行为守则》进行修订，主要从以下几方面对第三方资助人进行规制：（1）案件信息进行保密；（2）保证资本充足；（3）限制单方解约；（4）不得对诉讼和仲裁程序进行控制和干预。（5）不得要求律师违反执业操守进行行为。</p>
    <p><b>2、澳大利亚通过法院判例来规制第三方资助人</b></p>
    <p>澳大利亚立法层面对第三方资助行为规定较少，主要通过法院的判例规则，来确定第三方资助行为规则。如：（1）诉讼投资协议有效。2006年Fostif案，澳大利亚高等法院基于诉讼资助在促进集团诉讼方面发挥的积极作用，认可了诉讼资助协议的效力。（2）信息披露义务。2009年Multiplex案。澳大利亚联邦法院判决，集团诉讼的诉讼资助构成2001年《公司法》所规定的“有管理的投资方案”，需要进行信息披露和登记。（3）利益冲突规制。2016年10月，澳大利亚联邦法院发布《集团诉讼操作要领》，第五、第六部分系统地规定了第三方资助的定义、形式要求、对费用安排的限制、利益冲突、信息披露等问题。</p>
    <p><b>3、美国通过立法和律师执业指引来规制第三方资助行为</b></p>
    <p>2014年6月，美国有7个州（缅因、印第安纳、俄亥俄、俄克拉荷马、内布拉斯加、纽约、田纳西）通过州立法明确第三方资助行为合法。但对是否披露第三方资助人的问题上，各州存有较大争议。2014年和2016年，美国商会两度提议对《联邦民事诉讼程序》第26条进行修订，要求所有民事案件一开始就自动披露第三方资助人，遭到立法机关拒绝，至今未果。</p>
    <p>为弥补立法对第三方资助规制的不足，美国多个州的律师职业道德指引，将律师与第三方资助人的潜在利益冲突纳入调整范围。如，未经客户同意，律师不得向第三方资助人披露案件保密信息；如律师持有第三方资助人股份，则不得向客户推荐该资助人；律师不得因向客户推荐第三方资助人而收取推荐费，或以其他方式从中谋利等等。</p>
    <p><b>4、新加坡通过多项立法规制第三方资助行为</b></p>
    <p>新加坡对第三方资助行为合法化保持高度警惕的同时，也制定了一系列规制措施。如：</p>
    <p>（1）发布《投资仲裁规则》（2017年1月1日生效），赋予仲裁庭命令当事人披露第三方资助存在的事实、第三方资助人身份的权力，特定情况下，仲裁庭还有权命令当事人披露第三方资助人在赢得裁决后可获得的利益、第三方资助人是否承诺承担败诉费用等信息。</p>
    <p>（2）2017年2月21日，制定《民法（第三方资助）规则2017》，将第三方资助的适用范围暂时限定在国际仲裁领域；明确了第三方资助人必须具备的条件，以第三方资助为主营业务，实缴资本不低于五百万美元或其他等额资金。</p>
    <p>（3）修订《法律执业法》和《法律执业规则》，允许律师向客户推荐第三方资助人，明确律师不得借机谋求正常法律服务费用之外的推荐费等好处。律师必须向仲裁庭以及其他当事方披露第三方资助协议存在之事实以及第三方资助人的身份，此项披露应在纠纷解决程序开始之时。律师不得直接或间接持有第三方资助人的股权等等。</p>
    <p><b>5、我国香港制定《仲裁条例》规制第三方资助行为</b></p>
    <p>香港借鉴了英格兰和威尔士的行业自律模式，但又有所改良。香港制定了《仲裁条例》，对第三方资助仲裁行为作了更为全面的要求。如：（1）被资助人披露资助行为义务。（2）被资助人独立意见权利。（3）第三方资助人最低资本要求。（4）第三方资助人内控机制。（5）资助协议必备条款等内容。</p>
    <p><b class="red">四、诉讼投资是否会影响民事诉讼秩序中的公序良俗？</b></p>
    <p>《民法典》第8条规定：“民事主体从事民事活动，不得违反法律，不得违背公序良俗。”《民法典》第10条规定：“处理民事纠纷，应当依照法律；法律没有规定的，可以适用习惯，但是不得违背公序良俗。”《民法典》第153条规定：“违反法律、行政法规的强制性规定的民事法律行为无效。但是，该强制性规定不导致该民事法律行为无效的除外。违背公序良俗的民事法律行为无效。”</p>
    <p>“公序良俗”是法律词汇，理解为“公共秩序”和“善良风俗”。</p>
    <p>公序良俗是近代民法核心原则之一，亦是对民事领域意思自治的一种限制，随着“西法东渐”的进程，“公序良俗”概念的正式成型应该归结于清末修律的活动之中，在民法中的中文表述首见于1911年《大清民律草案》，原文是：</p>
    <p>
      <b>第五十条</b>自由不得抛弃。不得违背<b>公共秩序或善良风俗</b>而限制自由。
    </p>
    <p>
      <b>第一百七十五条</b>以违<b>公共秩序</b>之事项为标的者，其法律行为无效。
    </p>
    <p>
      <b>第九百七十四条</b>以背于<b>善良风俗</b>之方法，故意加损害于他人者，视为第九百四十五条之加害人。
    </p>
    <p>
      后遍历修法沿用至今，但<b>含义和边界并不十分明晰</b>。
    </p>
    <p>
      据考据，“公序良俗”作为合称出现，首见于民国19年（1930年）司法部对于江苏高等法院的批示中，并在同一文件中再次扩展为“公共秩序善良风俗”：
    </p>
    <p style="text-align: center">
      <img :src="require('../assets/new1.png')" style="width: 100%" />
    </p>
    <p>
      在《中华人民共和国民法总则释解与适用》中，对第一百五十三条“违背公序良俗的民事法律行为无效。”的立法观点进行了阐释：
    </p>
    <p style="border-left: 1px solid #ddd; padding-left: 20px; color: #888">
      本条第二款规定，违背公序良俗的民事法律行为无效。公序良俗是公共秩序和善良习俗的简称，属于不确定概念。民法学说一般采取类型化研究的方式，将裁判实务中依据公序良俗裁判的典型案件，区别为若干公序良俗违反的行为类型。
    </p>
    <p>
      公共秩序，指国家社会的存在及其发展所必需的一般秩序；善良风俗，指国家社会的存在及其发展所必需的一般道德。
    </p>
    <p>公序良俗原则，强调民事主体的行为应当遵守公共秩序，符合善良风俗，不得违反国家的公共秩序和社会的一般道德，否则将突破意思自治原则。</p>
    <p>至于什么样的秩序观和道德观属于公序良俗，<b>法律并未枚举，一切尽凭裁量。</b></p>
    <p><b>这就免不了见仁见智，但并非无法认识。</b></p>
    <p>当我们用一般社会大众的基本认识水平和基本道德水平去分析和评判其行为可能造成的后果时，也许或多或少能窥探出其背后要义。</p>
    <p><b class="red">诉讼投资是否会影响公序良俗？</b></p>
    <p>首先，<b class="red">诉讼投资是否会冲击到正常的民事诉讼秩序</b>？<b>我们认为，诉讼投资不会影响到基本的民事诉讼秩序</b>。</p>
    <p>民事诉讼有三大基石：当事人的诉权，律师的代理权，法院的独立审判权。</p>
    <p><b>1、诉讼投资不影响当事人的诉权。</b></p>
    <p>诉讼投资机构对诉讼案件的一方当事人资助，<b>是以存在合法诉权为前提的</b>，不仅不会影响当事人诉权，更加<b>是在保护当事人提起诉讼的权利能力</b>。</p>
    <p><b class="title">正如习总书记在十八届中央政治局第四次集体学习时的讲话所言：“如果群众有了司法需求，需要打官司，一没有钱去打，二没有律师可以求助，公正司法从何而来呢？”</b></p>
    <p>如上所述，诉讼需缴诉讼费，商业主体也概莫能外。而商业主体普遍不符合“减免缓”交诉讼费条件，越大标的商事案件，越不符合“减免缓”条件。诉讼费作为法定收费项目，不交费根本无法启动诉讼程序。诉讼投资业务可保护当事人的诉权不因资金困难而受限。</p>
    <p>任何有权要求平等待遇的人，都应该能够从任何愿意提供平等权利的人那儿获得资助，无论以什么形式。<b>既然进入法院是一项权利，那么努力实现这一权利的、合法的、法未禁止的行为，就不应被妨碍。</b></p>
    <p>有观点认为这会引发滥诉行为，这未免对投资者的收益诉求和被投资者的止损诉求太过不了解。<b class="red">在当代法治社会背景和中国特色社会主义市场经济体制下，对于一个难以获得法律及或司法机关支持的诉权，因诉投行为引发滥诉的概率，可谓微乎其微，担心诉投机构可能投资此类诉权，更是杞人忧天</b>。</p>
    <p>投资者的理性投资行为，绝非赌博式的投机和冒险，必定有调研、分析、决策、投资、管理、退出等操作流程，想获得一份诉讼投资，以帮瀛80%以上的拒项率看，绝非易事。而被资助者也并非懵懂无知任人宰割的羔羊和走投无路孤注一掷的赌徒，机会成本极高，其也会在充分竞争的法律服务市场中和金融服务市场中，多番综合比较，反复思索试探，拉锯式谈判磋商，最终经历重重困难，方可能达成合作。</p>
    <p>另外，诉讼投资的资助协议，大多不会限制当事人的诉讼权利，对于提起诉讼，撤诉，调解，和解，上诉，再审等决策，最终决定权都在当事人手中，协议中一般只会约定特定情形下的违约责任，并不可能限制当事人的诉权，事实上该权利为当事人的法定权利，没有当事人的授权或签字，根本不可能顺利实施。</p>
    <p><b>2、诉讼投资不影响律师的代理权</b></p>
    <p>依据《律师法》和《民事诉讼法》等规定，律师有代理案件的权利，诉投机构资助的案件，代理人也依然是律师，并非诉投机构自己的工作人员。律师在案件代理工作中，依然是独自代理和按照律师职业规范进行代理。虽然有些诉投机构要求律师将相关法律文件及时提交诉投机构，或要求律师就相关法律意见进行相应汇报，但这些多是基于对投资行为履行的<b>投后管理职责</b>，并不会干预律师行动或代律师发表意见，律师代理权依然在律师手中，法律文件及意见的撰写、提交、举证、质证、答辩和陈述的意见，依然是由律师发表，诉投机构基本不参与律师代理过程，也不干扰律师代理案件活动。</p>
    <p>3、诉讼投资不影响法院的独立审判权</p>
    <p>案件的立案，开庭、审理、证据采信、判决，调解、执行，完全都是法院主导和推动。法院有独立审判权，法官都是专职人员，法院在案件进程的全流程中，处于中立和超脱地位，又处于绝对主导和控制地位。</p>
    <p>诉投机构作为商业主体，作为非案件当事人一方，既没有权利在案件中出现，也没有权利在案件中提出任何意见，更不会出现在案件的任何一个审判环节中，对法院来说，完全是一个绝缘体或透明体的存在，其根本不具备影响法院或法官独立审判的丝毫能力。</p>
    <p><b>有观点担心，诉投机构可能与法官存在利益关联，影响裁判独立性和公正性。</b></p>
    <p>因法官都是专职人员，不可能在诉投机构任职或有股份，其和诉投机构有利益关联，主要表现在诉投机构可能输送不法利益，或诉投机构的法人或实控人是法官近亲属，可能控制和影响司法公正，但这一担心普遍存在于任何一个商业主体或自然人，并非诉讼投资机构之特性，而这风险的源头，显然更加不在诉投机构。</p>
    <p><b>其次，担心诉讼投资模式对民事诉讼秩序造成冲击。</b></p>
    <p>根据Alpha查询显示，2020年全年民事案件数量（标的额在1万元以上）共计573.5万件，其中88.09%是50万元以下标的案件，1000万以上标的案件，占比不到1%。</p>
    <p>
      <img :src="require('../assets/new2.png')" style="width: 100%" />
    </p>
    <p>如前所述，大部分民事案件并非诉投案件的投资范围，诉投案件主要处理的是平等主体之间的商事纠纷。</p>
    <p>以帮瀛的5000万投资标准为例，占比不超过民商事案件总量的0.2%，99.8%以上的案件未达到投资标准，融资方几乎全是商业主体，即便有自然人作为被资助方，也基本上是公司股东、建筑行业实际施工人或大额民间借贷的债权人。即便以50万为门槛，该类案件在民商事诉讼案件总量中，占比也不过12%左右。</p>
    <p>这些只是案件辐射的标的范围，如果考虑案件诉求的合理合法性，案件执行的可能性，当事人的信用情况，案件的收费标准，案件的磋商过程，<b>可能进入诉讼投资领域的案件，不超过民商事诉讼案件总量的万分之一</b>，如此低的数量，对民事诉讼秩序造成所谓冲击，纯粹是纸上谈兵的臆想。</p>
    <p><b>最后，担心诉讼投资对民众价值观的影响。</b></p>
    <p>诉权只是一种权利，并非资产，只有合理合法并可能实现权益的诉讼请求，才可能成为有价值的资产。</p>
    <p>我国允许债权的转让，允许不良资产的买卖，允许判决书申请执行人的变更，允许应收账款的质押登记，为什么？</p>
    <p><b>因为债权从本质上讲，是一种私权。</b></p>
    <p><b class="red">对私权的处置，法律是不应轻易干预的。</b></p>
    <p>民法作为典型的私法，其体系的构建是以权利为核心和基础的，保护私权是其目标，也是其价值所在。</p>
    <p>诚然，权利并非无限度、私权不能漫无边界，但<b>相较于私权利不可滥用，公权力的边界划分更为重要</b>，公权力在介入私权领域时，应更加理性地掌控其力度与限度，<b>遵守私权为本、公权为用的原则，持守谦抑</b>。</p>
    <p><b>像“公序良俗”这种负面性评价原则和主观认定性原则，因其可以直接否认民事行为的效力，更加应该被谨慎地使用</b>，<b class="red">尤其要结合当下的、本法域内的社会体制对“公序”进行判定，不应简单地参考不同时代、不同文化的其他社会背景对“良俗”进行类比</b>，<b>以免破坏作为民法基石的“意思自治”、“诚实信用”、“公平交易”和“权利保护”原则的主导地位，避免私权领域中“法无禁止皆可为”的神圣原则和理念沦为空谈和笑柄</b>。</p>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
  name: 'Home',
  setup() {
    let type = ref(true)
    onMounted(() => {
      type.value = localStorage.getItem('type')
    })
    return {
      type
    }
  }
}
</script>

<style scoped lang="scss">
  @media screen and (min-width: 667px){
  .detailContent {
    width: 667px;
  }
  }
  .detailContent {
    margin: 0 auto;
    // width: 677px;
    padding: 50px;
    background: #fff;
    font-size: 16px;
    color: #555;
    p, div {
      line-height: 40px;
    }
    p {
      margin-bottom: 10px;
    }
    .header {
      color: #000;
      font-weight: bold;
      font-size: 24px;
      margin: 20px 0;
      text-align: center;
    }
    .title {
      color: #1c447a;
    }
    .red {
      color: #9d2a44;
    }
  }
</style>